import React, { ReactNode, useCallback } from "react";
import { isBefore } from "date-fns";
import { useNavigate } from "react-router-dom";
import AppointmentInformationRequiredCard from "../AppointmentInformationRequiredCard";
import AppointmentPreviewActionButtons from "../AppointmentPreviewActionButtons";
import { ActionButton } from "../AppointmentPreviewActionButtons/types";
import { requiredInformationButtonProps } from "./constants";
import { Appointment } from "./types";
import useTranslations from "./useTranslations";
import { getNowUTC } from "../../../common/dateHelpers";
import { useFormContext } from "../../Unknown/FormContext";
import Grid from "../../Unknown/Grid";
import AppointmentPreviewReportsList from "../AppointmentPreviewReportsList";

const useAppointmentListItemChildrenGetter = () => {
  const navigate = useNavigate();

  const {
    titleInformationRequired,
    descriptionInformationRequired,
    btnEditPatient,
    btnPatientInformation,
    btnFormPrivacy,
    btnFormConsent,
    btnFormScreening,
  } = useTranslations();

  const { setFormAppointmentId, setFormPatientId } = useFormContext();

  const appointmentListItemChildrenGetter = useCallback(
    (appointment: Appointment): ReactNode | null => {
      const actionButtons: ActionButton[] = [];

      const isAppointmentCheckedIn = appointment.status === "checked_in";
      const isAppointmentPatientDetailsProvided =
        !!appointment.appointment_patient_details_form?.[0];
      const appointmentPatient =
        appointment.appointment_patient_details[0].patient;
      const existedPatientPrivacyForm = appointmentPatient.patient_forms.find(
        (patientForm) => patientForm.type === "privacy",
      );
      const existedPatientConsentForm = appointmentPatient.patient_forms.find(
        (patientForm) => patientForm.type === "consent",
      );
      const isAppointmentScreeningFormProvided =
        !!appointment.appointment_forms.find(
          (appointmentForm) => appointmentForm.type === "screening",
        );

      const isValidPrivacyForm =
        existedPatientPrivacyForm &&
        isBefore(
          getNowUTC(),
          new Date(existedPatientPrivacyForm.expiration_at),
        );
      const isValidConsentForm =
        existedPatientConsentForm &&
        isBefore(
          getNowUTC(),
          new Date(existedPatientConsentForm.expiration_at),
        );

      const providePatientInformationformPath = isAppointmentCheckedIn
        ? "change-patient-information"
        : "provide-patient-information";

      if (!isAppointmentPatientDetailsProvided) {
        actionButtons.push({
          id: "patientInformation",
          text: btnPatientInformation,
          props: {
            variant: "contained",
            onClick: () =>
              navigate(
                `/appointment/${appointment.id}/${providePatientInformationformPath}`,
              ),
          },
        });
      }

      if (isAppointmentPatientDetailsProvided) {
        actionButtons.push({
          id: "editPatient",
          text: btnEditPatient,
          props: {
            variant: "outlined",
            onClick: () =>
              navigate(
                `/appointment/${appointment.id}/${providePatientInformationformPath}`,
              ),
          },
        });
      }

      if (!isValidPrivacyForm) {
        actionButtons.push({
          id: "formPrivacy",
          text: btnFormPrivacy,
          props: {
            ...requiredInformationButtonProps.default,
            onClick: () => {
              setFormPatientId(appointmentPatient.id);
              setFormAppointmentId(appointment.id);
              navigate(`/provide-privacy-form`);
            },
          },
        });
      }

      if (!isValidConsentForm) {
        actionButtons.push({
          id: "formConsent",
          text: btnFormConsent,
          props: {
            ...requiredInformationButtonProps.default,
            onClick: () => {
              setFormPatientId(appointmentPatient.id);
              setFormAppointmentId(appointment.id);
              navigate(`/provide-consent-form`);
            },
          },
        });
      }
      if (!isAppointmentScreeningFormProvided) {
        actionButtons.push({
          id: "formScreening",
          text: btnFormScreening,
          props: {
            ...requiredInformationButtonProps.default,
            onClick: () => {
              setFormAppointmentId(appointment.id);
              navigate("/provide-screening-form");
            },
          },
        });
      }

      if (appointment.status === "confirmed") {
        return (
          <AppointmentInformationRequiredCard
            title={titleInformationRequired}
            description={descriptionInformationRequired}
            actionButtons={actionButtons}
          />
        );
      }

      const isAppointmentReportsSent = appointment.status === "reports_sent";

      return (
        <Grid container gap={4}>
          <AppointmentPreviewActionButtons actionButtons={actionButtons} />
          {isAppointmentReportsSent && (
            <AppointmentPreviewReportsList
              appointmentReports={appointment.appointment_reports}
              appointmentId={appointment.id}
              patientId={appointmentPatient.id}
            />
          )}
        </Grid>
      );
    },
    [
      btnPatientInformation,
      btnEditPatient,
      btnFormPrivacy,
      navigate,
      setFormPatientId,
      setFormAppointmentId,
      btnFormConsent,
      btnFormScreening,
      titleInformationRequired,
      descriptionInformationRequired,
    ],
  );

  return appointmentListItemChildrenGetter;
};

export default useAppointmentListItemChildrenGetter;
